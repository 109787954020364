import React from 'react';
import { Clinic, getDirectionsUrl, getPaymentAccepted, getServiceSetting, getTypeOfCare } from './Clinic';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import styles from './ClinicSummary.module.css';
import { FaDirections, FaPhone, FaStar } from 'react-icons/fa';
import ClinicQueryData from './ClinicQueryData';

export interface ClinicSummaryProps {
  clinic: Clinic;
  queryData: ClinicQueryData;
  showDetails: Function;
}

const MATcodes = ['bu', 'meth', 'nxn'];

function ClinicSummary(props: ClinicSummaryProps): JSX.Element{
  const phoneLink = 'tel:' + props?.clinic?.phone;
  const handleMoreInfo = (): void => {
    props.showDetails(true, props.clinic.uid);
  };
  return (
    <React.Fragment>
      <Card key={props?.clinic?.uid} className={styles.summaryCard}>
        <CardHeader className={styles.cardHeader} onClick={handleMoreInfo}>
          {/* Show message if the clinic offers MAT */}
          {props.clinic.codes.some(el=>MATcodes.includes(el)) &&
            <div className={styles.MATMessage}>
              <FaStar size="1.6em"/>   This clinic offers MAT
            </div>
          }
          <div className={styles.toggleClick} onClick={handleMoreInfo}>
            {props?.clinic?.name2 &&
            <div className={styles.nameHeader}>
              <div className={styles.clinicName1}>{props?.clinic?.name}</div>
              <div className={styles.clinicName2}>{props?.clinic?.name2}</div>
            </div>
            }
            {!props?.clinic?.name2 &&
            <div className={styles.nameHeader}>
              <div className={styles.clinicName1}>{props?.clinic?.name}</div>
            </div>
            }
            <Button className={styles.moreLink} color="link">Clinic
              Details</Button>
          </div>
        </CardHeader>
        <CardBody className={props.clinic.codes.some(el=>MATcodes.includes(el))?styles.MATClinic:styles.notMATClinic}>
          <Row>
            <Col md={3}>
              <div className={styles.mapLink}>
                <span className={styles.mapLinkIcon}><FaDirections/></span>
                <Button color="link"
                        href={getDirectionsUrl(props?.clinic, props?.queryData?.searchLocation)}
                        target="_blank"
                        rel="noopener noreferrer">{props?.clinic?.distanceInMiles} miles</Button></div>
            </Col>
            <Col md={9}>
              <a href={phoneLink} className={styles.clinicPhone}><span
                className={styles.clinicPhoneIcon}> <FaPhone/></span>{props?.clinic?.phone}</a>
              {props?.clinic?.website?.length > 8 &&
              <div className={styles.clinicWebsite}><Button color="link" href={props?.clinic?.website}
                                                            target="_blank"
                                                            rel="noopener noreferrer"> {props?.clinic?.website}</Button>
              </div>
              }
              {props?.clinic?.website?.length <= 8 &&
              <div className={styles.clinicWebsite}>No Website Reported</div>
              }
              <div><Button color="link"
                           href={getDirectionsUrl(props?.clinic, props?.queryData?.searchLocation)}
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.clinicAddress}>{props?.clinic?.street1} {props?.clinic?.street2} {props?.clinic?.city} {props?.clinic?.state} {props?.clinic?.zip}</Button>
              </div>
              <div className={styles.serviceLabel}>Services: <span
                className={styles.serviceString}>{getServiceSetting(props?.clinic).join(', ')}, {getTypeOfCare(props?.clinic).join(', ')}</span>
              </div>
              <div className={styles.paymentTypeLabel}>Payment Accepted: <span
                className={styles.paymentTypeString}>{getPaymentAccepted(props?.clinic).join(', ')}</span>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default ClinicSummary;
