import Location from './Location';
import SearchHeaderObject from '../search/SearchHeaderObject';
import SearchEntryObject from '../search/SearchEntryObject';

export class ClinicQueryResponse {
  clinics: Array<Clinic>;
  total_elements: number;
}
export class Clinic {
  active = true;
  uid: string;
  name: string;
  name2: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  zip4: string;
  county: string;
  phone: string;
  intakePrompt: string;
  intake1: string;
  intake2: string;
  website: string;
  facilityType: string;
  latitude: number;
  longitude: number;
  distanceInKm = 0;
  distanceInMiles = 0;
  // Begin Codes
  codes: string[] = [];

  static compare(a: Clinic, b: Clinic): number{
    return a.distanceInMiles - b.distanceInMiles;
  }
}
export const buildLanguageList = (): Array<Record<string, string>> => {
  const languages: Array<Record<string, string>> = [];
  languages.push({ 'name': '----', 'value': '', key: 'unknown-language' });
  languages.push({ 'name': 'Arabic', 'value': 'f4', key: 'f4' });
  languages.push({ 'name': 'Cherokee', 'value': 'n4', key: 'n4' });
  languages.push({ 'name': 'Chinese', 'value': 'f17', key: 'f17' });
  languages.push({ 'name': 'French', 'value': 'f28', key: 'f28' });
  languages.push({ 'name': 'German', 'value': 'f30', key: 'f30' });
  languages.push({ 'name': 'Keres', 'value': 'n16', key: 'n16' });
  languages.push({ 'name': 'Korean', 'value': 'f47', key: 'f47' });
  languages.push({ 'name': 'Navajo', 'value': 'n23', key: 'n23' });
  languages.push({ 'name': 'Ojibwa', 'value': 'n24', key: 'n24' });
  languages.push({ 'name': 'Russian', 'value': 'f70', key: 'f70' });
  languages.push({ 'name': 'Spanish', 'value': 'sp', key: 'sp' });
  languages.push({ 'name': 'Tagalog', 'value': 'f81', key: 'f81' });
  languages.push({ 'name': 'Vietnamese', 'value': 'f92', key: 'f92' });
  languages.push({ 'name': 'Yupik', 'value': 'n40', key: 'n40' });
  languages.push({ 'name': 'Zuni', 'value': 'n41', key: 'n41' });
  return languages;
}
export const buildSearchCriteria = (): Array<SearchHeaderObject> => {
  const searchCriteria: Array<SearchHeaderObject> = [];
  // Treatment Types
  const treatmentTypeEntries: Array<SearchEntryObject> = [];
  const searchHeaderObject2 = new SearchHeaderObject('Treatment Type', treatmentTypeEntries);
  searchCriteria.push(searchHeaderObject2);
  treatmentTypeEntries.push(new SearchEntryObject('allTreatmentTypes', true, 'treatmentTypeCode',
    '', 'All Treatment Types', ''));
  treatmentTypeEntries.push(new SearchEntryObject('InterimCare', false, 'treatmentTypeCode',
    'ic', 'Interim Care',
    'When immediate admission to other care isn’t available'));
  treatmentTypeEntries.push(new SearchEntryObject('Detox', false, 'treatmentTypeCode',
    'dt', 'Detox', 'Supervised withdrawal from substance use'));
  treatmentTypeEntries.push(new SearchEntryObject('Outpatient', false, 'treatmentTypeCode',
    'op', 'Outpatient', 'Treatment at a program site while a patient lives on their own'));
  treatmentTypeEntries.push(new SearchEntryObject('HospitalInpatient', false, 'treatmentTypeCode',
    'hi', 'Hospital Inpatient', '24/7 care connected to a hospital, lasting days or weeks'));
  treatmentTypeEntries.push(new SearchEntryObject('Residential', false, 'treatmentTypeCode',
    're', 'Residential', 'Live-in care, lasting for one month to one year'));
  treatmentTypeEntries.push(new SearchEntryObject('TransitionalHousing', false, 'treatmentTypeCode',
    'hh', 'Transitional Housing', 'Temporary space to stay while transitioning from an intensive treatment setting; sometimes called a halfway house or sober living'));
  // Age Types
  const ageEntries: Array<SearchEntryObject> = [];
  const searchHeaderObject1 = new SearchHeaderObject('Age', ageEntries);
  searchCriteria.push(searchHeaderObject1);
  ageEntries.push(new SearchEntryObject('allAge', true, 'ageCode', '', 'All Ages', ''));
  ageEntries.push(new SearchEntryObject('adultAge', false, 'ageCode', 'adlt', 'Adult (18 and Older)', ''));
  ageEntries.push(new SearchEntryObject('youthAge', false, 'ageCode', 'yad', 'Young Adults', ''));
  ageEntries.push(new SearchEntryObject('childAge', false, 'ageCode', 'chld', 'Children / Adolescents', ''));
  // Payment Options
  const paymentOptionEntries: Array<SearchEntryObject> = [];
  const searchHeaderObject3 = new SearchHeaderObject('Payment Options', paymentOptionEntries);
  searchCriteria.push(searchHeaderObject3);
  paymentOptionEntries.push(new SearchEntryObject('allPaymentOptions', true, 'paymentOptionCode',
    '', 'All Payment Options', ''));
  paymentOptionEntries.push(new SearchEntryObject('privatePaymentOption', false, 'paymentOptionCode',
    'pi', 'Private health insurance', ''));
  paymentOptionEntries.push(new SearchEntryObject('medicaidPaymentType', false, 'paymentOptionCode',
    'md', 'Medicaid', ''));
  paymentOptionEntries.push(new SearchEntryObject('medicarePaymentOption', false, 'paymentOptionCode',
    'mc', 'Medicare', ''));
  paymentOptionEntries.push(new SearchEntryObject('assistancePaymentOption', false, 'paymentOptionCode',
    'pa', 'Payment Assistance Available', 'Facilities that may offer help paying for services. Check with the facility for details.'));
  paymentOptionEntries.push(new SearchEntryObject('slidingFeePaymentOption', false, 'paymentOptionCode',
    'ss', 'Sliding fee scale', 'Adjustable fees based on income.'));
  paymentOptionEntries.push(new SearchEntryObject('cashPaymentOption', false, 'paymentOptionCode',
    'sf', 'Cash or self-payment', ''));
  paymentOptionEntries.push(new SearchEntryObject('militaryPaymentOption', false, 'paymentOptionCode',
    'mi', 'Military insurance (e.g. TRICARE)', ''));
  paymentOptionEntries.push(new SearchEntryObject('tribalPaymentOption', false, 'paymentOptionCode',
    'itu', 'IHS/Tribal/Urban (ITU) funds', ''));
  // Special Programs
  const specialProgramEntries: Array<SearchEntryObject> = [];
  const searchHeaderObject4 = new SearchHeaderObject('Special Programs', specialProgramEntries);
  searchCriteria.push(searchHeaderObject4);
  specialProgramEntries.push(new SearchEntryObject('allSpecialPrograms', true, 'specialProgramCode',
    '', 'All Special Programs', ''));
  specialProgramEntries.push(new SearchEntryObject('veteransProgram', false, 'specialProgramCode',
    'vet', 'Veterans', ''));
  specialProgramEntries.push(new SearchEntryObject('lgbtProgram', false, 'specialProgramCode',
    'gl', 'LGBT (Lesbian, gay, bisexual, transgender)', ''));
  specialProgramEntries.push(new SearchEntryObject('deafProgram', false, 'specialProgramCode',
    'ah', 'Services for the deaf and hard of hearing', ''));
  // Mat Types
  const matTypeEntries: Array<SearchEntryObject> = [];
  const searchHeaderObject5 = new SearchHeaderObject('Medication for Addiction Treatment (MAT)', matTypeEntries);
  searchCriteria.push(searchHeaderObject5);
  matTypeEntries.push(new SearchEntryObject('allMatTypes', true, 'matCode',
    '', 'All MAT Types', ''));
  matTypeEntries.push(new SearchEntryObject('methMatType', false, 'matCode',
    'meth', 'Methadone', ''));
  matTypeEntries.push(new SearchEntryObject('bupMatType', false, 'matCode',
    'bu', 'Buprenorphine', ''));
  matTypeEntries.push(new SearchEntryObject('NalMatType', false, 'matCode',
    'nxn', 'Naltrexone', ''));
  return searchCriteria;
}
export const getAgeGroups = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('adlt') ? returnData.concat('Adults') : returnData;
  returnData = clinic.codes.includes('chld') ? returnData.concat('Children/adolescents') : returnData;
  returnData = clinic.codes.includes('yad') ? returnData.concat('Young adults') : returnData;
  return returnData;
}
export const getGenderAccepted = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('fem') ? returnData.concat('Female') : returnData;
  returnData = clinic.codes.includes('male') ? returnData.concat('Male') : returnData;
  return returnData;
}
export const getCounselingAndEducationServices = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('saca') ? returnData.concat('Substance abuse counseling') : returnData;
  returnData = clinic.codes.includes('trc') ? returnData.concat('Trauma-related counseling') : returnData;
  returnData = clinic.codes.includes('emp') ? returnData.concat('Employment counseling or training') : returnData;
  returnData = clinic.codes.includes('fco') ? returnData.concat('Family counseling offered') : returnData;
  returnData = clinic.codes.includes('gco') ? returnData.concat('Group counseling offered') : returnData;
  returnData = clinic.codes.includes('haec') ? returnData.concat('HIV or AIDS education, counseling, or support') : returnData;
  returnData = clinic.codes.includes('ico') ? returnData.concat('Individual counseling offered') : returnData;
  returnData = clinic.codes.includes('mco') ? returnData.concat('Marital/couples counseling offered') : returnData;
  returnData = clinic.codes.includes('taec') ? returnData.concat('Hepatitis education, counseling, or support') : returnData;
  returnData = clinic.codes.includes('tcc') ? returnData.concat('Smoking/tobacco cessation counseling') : returnData;
  returnData = clinic.codes.includes('heoh') ? returnData.concat('Health education services other than HIV/ AIDS or hepatitis') : returnData;
  returnData = clinic.codes.includes('sae') ? returnData.concat('Substance abuse education') : returnData;
  return returnData;
}
export const getTransitionalServices = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('dp') ? returnData.concat('Discharge planning') : returnData;
  return returnData;
}
export const getAssessmentPreTreatment = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('csaa') ? returnData.concat('Comprehensive mental health assessment') : returnData;
  returnData = clinic.codes.includes('isc') ? returnData.concat('Interim services for clients') : returnData;
  return returnData;
}
export const getOpioidServices = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('odtx') ? returnData.concat('Opioid detoxification') : returnData;
  returnData = clinic.codes.includes('moa') ? returnData.concat('Accepts clients on opioid medication') : returnData;
  returnData = clinic.codes.includes('nmoa') ? returnData.concat('Does not use medication for opioid addiction') : returnData;
  returnData = clinic.codes.includes('noop') ? returnData.concat('Does not treat opioid addiction') : returnData;
  returnData = clinic.codes.includes('otp') ? returnData.concat('SAMHSA-certified Opioid Treatment Program') : returnData;
  returnData = clinic.codes.includes('otpa') ? returnData.concat('All clients in Opioid Treatment Program') : returnData;
  returnData = clinic.codes.includes('bm') ? returnData.concat('Buprenorphine maintenance') : returnData;
  returnData = clinic.codes.includes('bmw') ? returnData.concat('Buprenorphine maintenance for a predetermined time') : returnData;
  return returnData;
}
export const getExclusiveServices = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('bmo') ? returnData.concat('Methadone and buprenorphine clients only') : returnData;
  returnData = clinic.codes.includes('du') ? returnData.concat('DUI/DWI clients') : returnData;
  returnData = clinic.codes.includes('duo') ? returnData.concat('Serves only DUI/DWI clients') : returnData;
  returnData = clinic.codes.includes('mo') ? returnData.concat('Methadone clients only') : returnData;
  return returnData;
}
export const getPrimaryFocus = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ghf') ? returnData.concat('General Health Services') : returnData;
  returnData = clinic.codes.includes('mhf') ? returnData.concat('Mental Health Treatment Services') : returnData;
  returnData = clinic.codes.includes('mhsaf') ? returnData.concat('Mix of Mental Health and Substance Abuse') : returnData;
  returnData = clinic.codes.includes('saf') ? returnData.concat('Substance Abuse Treatment Services') : returnData;
  return returnData;
}
export const getTypeOfCare = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('acm') ? returnData.concat('Acamprosate (Campral®)') : returnData;
  returnData = clinic.codes.includes('bu') ? returnData.concat('Buprenorphine used in treatment') : returnData;
  returnData = clinic.codes.includes('db') ? returnData.concat('Buprenorphine detoxification') : returnData;
  returnData = clinic.codes.includes('dm') ? returnData.concat('Methadone detoxification') : returnData;
  returnData = clinic.codes.includes('dsf') ? returnData.concat('Disulfiram (Antabuse®)') : returnData;
  returnData = clinic.codes.includes('dt') ? returnData.concat('Detoxification') : returnData;
  returnData = clinic.codes.includes('hh') ? returnData.concat('Transitional housing or halfway house') : returnData;
  returnData = clinic.codes.includes('meth') ? returnData.concat('Methadone') : returnData;
  returnData = clinic.codes.includes('mm') ? returnData.concat('Methadone maintenance') : returnData;
  returnData = clinic.codes.includes('mmw') ? returnData.concat('Methadone maintenance for pre-determined time') : returnData;
  returnData = clinic.codes.includes('mpd') ? returnData.concat('Medications for psychiatric disorders') : returnData;
  returnData = clinic.codes.includes('nxn') ? returnData.concat('Naltrexone (oral)') : returnData;
  returnData = clinic.codes.includes('pain') ? returnData.concat('Use methadone/buprenorphine for pain management or emergency dosing') : returnData;
  returnData = clinic.codes.includes('rpn') ? returnData.concat('Relapse prevention with naltrexone') : returnData;
  returnData = clinic.codes.includes('sa') ? returnData.concat('Substance abuse treatment') : returnData;
  returnData = clinic.codes.includes('ubn') ? returnData.concat('Prescribes/administers buprenorphine and/or naltrexone') : returnData;
  returnData = clinic.codes.includes('vtrl') ? returnData.concat('Extended-release, injectable naltrexone (Vivitrol®)') : returnData;
  return returnData;
}
export const getTreatmentApproaches = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ang') ? returnData.concat('Anger management') : returnData;
  returnData = clinic.codes.includes('bia') ? returnData.concat('Brief intervention') : returnData;
  returnData = clinic.codes.includes('cbt') ? returnData.concat('Cognitive behavioral therapy') : returnData;
  returnData = clinic.codes.includes('cmi') ? returnData.concat('Contingency management/motivational incentives') : returnData;
  returnData = clinic.codes.includes('crv') ? returnData.concat('Community reinforcement plus vouchers') : returnData;
  returnData = clinic.codes.includes('dbt') ? returnData.concat('Dialectical behavioral therapy') : returnData;
  returnData = clinic.codes.includes('moti') ? returnData.concat('Motivational interviewing') : returnData;
  returnData = clinic.codes.includes('mxm') ? returnData.concat('Matrix Model') : returnData;
  returnData = clinic.codes.includes('rebt') ? returnData.concat('Rational emotive behavioral therapy') : returnData;
  returnData = clinic.codes.includes('relp') ? returnData.concat('Relapse prevention') : returnData;
  returnData = clinic.codes.includes('twfa') ? returnData.concat('12-step facilitation') : returnData;
  return returnData;
}
export const getSmokingPolicy = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('smon') ? returnData.concat('Smoking not permitted') : returnData;
  returnData = clinic.codes.includes('smop') ? returnData.concat('Smoking permitted without restriction') : returnData;
  returnData = clinic.codes.includes('smpd') ? returnData.concat('Smoking permitted in designated area') : returnData;
  return returnData;
}
export const getServiceSetting = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ct') ? returnData.concat('Computerized treatment') : returnData;
  returnData = clinic.codes.includes('gh') ? returnData.concat('General hospital (including VA hospital)') : returnData;
  returnData = clinic.codes.includes('hi') ? returnData.concat('Hospital inpatient') : returnData;
  returnData = clinic.codes.includes('hid') ? returnData.concat('Hospital inpatient detoxification') : returnData;
  returnData = clinic.codes.includes('hit') ? returnData.concat('Hospital inpatient treatment') : returnData;
  returnData = clinic.codes.includes('od') ? returnData.concat('Outpatient detoxification') : returnData;
  returnData = clinic.codes.includes('odt') ? returnData.concat('Outpatient day treatment or partial hospitalization') : returnData;
  returnData = clinic.codes.includes('oit') ? returnData.concat('Intensive outpatient treatment') : returnData;
  returnData = clinic.codes.includes('eop') ? returnData.concat('Enhanced outpatient treatment') : returnData;
  returnData = clinic.codes.includes('omb') ? returnData.concat('Outpatient methadone/buprenorphine or naltrexone treatment') : returnData;
  returnData = clinic.codes.includes('op') ? returnData.concat('Outpatient') : returnData;
  returnData = clinic.codes.includes('ort') ? returnData.concat('Regular outpatient treatment') : returnData;
  returnData = clinic.codes.includes('ph') ? returnData.concat('Partial hospitalization/day treatment') : returnData;
  returnData = clinic.codes.includes('psyh') ? returnData.concat('Psychiatric hospital') : returnData;
  returnData = clinic.codes.includes('rd') ? returnData.concat('Residential detoxification') : returnData;
  returnData = clinic.codes.includes('res') ? returnData.concat('Residential') : returnData;
  returnData = clinic.codes.includes('rl') ? returnData.concat('Long-term residential (more than 30 days)') : returnData;
  returnData = clinic.codes.includes('rs') ? returnData.concat('Short-term residential (30 days or less)') : returnData;
  return returnData;
}
export const getFacilityOperation = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ddf') ? returnData.concat('Department of Defense') : returnData;
  returnData = clinic.codes.includes('ih') ? returnData.concat('Indian Health Service') : returnData;
  returnData = clinic.codes.includes('lccg') ? returnData.concat('Local, county, or community government') : returnData;
  returnData = clinic.codes.includes('pvt') ? returnData.concat('Private organization') : returnData;
  returnData = clinic.codes.includes('stg') ? returnData.concat('State government') : returnData;
  returnData = clinic.codes.includes('tbg') ? returnData.concat('Tribal government') : returnData;
  returnData = clinic.codes.includes('vamc') ? returnData.concat('U.S. Department of Veterans Affairs') : returnData;
  return returnData;
}
export const getPaymentAccepted = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('atr') ? returnData.concat('Access to Recovery (ATR) vouchers') : returnData;
  returnData = clinic.codes.includes('fsa') ? returnData.concat('Federal, or any government funding for substance abuse programs') : returnData;
  returnData = clinic.codes.includes('itu') ? returnData.concat('IHS/Tribal/Urban (ITU) funds') : returnData;
  returnData = clinic.codes.includes('mc') ? returnData.concat('Medicare') : returnData;
  returnData = clinic.codes.includes('md') ? returnData.concat('Medicaid') : returnData;
  returnData = clinic.codes.includes('mi') ? returnData.concat('Military insurance (e.g., VA, TRICARE)') : returnData;
  returnData = clinic.codes.includes('np') ? returnData.concat('No payment accepted') : returnData;
  returnData = clinic.codes.includes('pi') ? returnData.concat('Private health insurance') : returnData;
  returnData = clinic.codes.includes('sf') ? returnData.concat('Cash or self-payment') : returnData;
  returnData = clinic.codes.includes('si') ? returnData.concat('State financed insurance (other than Medicaid)') : returnData;
  return returnData;
}
export const getPaymentAssistance = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('pa') ? returnData.concat('Payment assistance (check with facility for details)') : returnData;
  returnData = clinic.codes.includes('ss') ? returnData.concat('Sliding fee scale (fee is based on income and other factors)') : returnData;
  return returnData;
}
export const getClientServicesFor = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ad') ? returnData.concat('Adolescents') : returnData;
  returnData = clinic.codes.includes('adm') ? returnData.concat('Active duty military') : returnData;
  returnData = clinic.codes.includes('cj') ? returnData.concat('Criminal justice clients') : returnData;
  returnData = clinic.codes.includes('co') ? returnData.concat('Persons with co-occurring mental and substance abuse disorders') : returnData;
  returnData = clinic.codes.includes('dv') ? returnData.concat('Persons who have experienced domestic violence') : returnData;
  returnData = clinic.codes.includes('gl') ? returnData.concat('Lesbian, gay, bisexual, or transgender (LGBT) clients') : returnData;
  returnData = clinic.codes.includes('hv') ? returnData.concat('Persons with HIV/AIDS') : returnData;
  returnData = clinic.codes.includes('mf') ? returnData.concat('Military families') : returnData;
  returnData = clinic.codes.includes('mn') ? returnData.concat('Adult men PW Pregnant/postpartum') : returnData;
  returnData = clinic.codes.includes('pw') ? returnData.concat('Pregnant/postpartum women') : returnData;
  returnData = clinic.codes.includes('se') ? returnData.concat('Seniors/older adults') : returnData;
  returnData = clinic.codes.includes('tay') ? returnData.concat('Transitional age young adults') : returnData;
  returnData = clinic.codes.includes('trma') ? returnData.concat('Persons who have experienced trauma') : returnData;
  returnData = clinic.codes.includes('vet') ? returnData.concat('Veterans') : returnData;
  returnData = clinic.codes.includes('wn') ? returnData.concat('Adult women') : returnData;
  returnData = clinic.codes.includes('xa') ? returnData.concat('Persons who have experienced sexual abuse') : returnData;
  return returnData;
}
export const getAccreditation = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('carf') ? returnData.concat('Commission on Accreditation of Rehabilitation Facilities') : returnData;
  returnData = clinic.codes.includes('coa') ? returnData.concat('Council on Accreditation') : returnData;
  returnData = clinic.codes.includes('heap') ? returnData.concat('Healthcare Facilities Accreditation Program') : returnData;
  returnData = clinic.codes.includes('hla') ? returnData.concat('Hospital licensing authority') : returnData;
  returnData = clinic.codes.includes('jc') ? returnData.concat('The Joint Commission') : returnData;
  returnData = clinic.codes.includes('ncqa') ? returnData.concat('National Committee for Quality Assurance') : returnData;
  returnData = clinic.codes.includes('stag') ? returnData.concat('State substance abuse agency') : returnData;
  returnData = clinic.codes.includes('stdh') ? returnData.concat('State department of health') : returnData;
  returnData = clinic.codes.includes('stmh') ? returnData.concat('State mental health department') : returnData;
  return returnData;
}
export const getAlcoholTreatment = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('adtx') ? returnData.concat('Alcohol detoxification') : returnData;
  returnData = clinic.codes.includes('baba') ? returnData.concat('Breath analyzer for blood alcohol testing') : returnData;
  return returnData;
}
export const getAncillaryServices = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('acc') ? returnData.concat('Aftercare/continuing care') : returnData;
  returnData = clinic.codes.includes('acu') ? returnData.concat('Acupuncture') : returnData;
  returnData = clinic.codes.includes('add') ? returnData.concat('Treatment for non-substance abuse addiction disorder') : returnData;
  returnData = clinic.codes.includes('aoss') ? returnData.concat('Assistance with obtaining social services') : returnData;
  returnData = clinic.codes.includes('bc') ? returnData.concat('Residential beds for clients’ children') : returnData;
  returnData = clinic.codes.includes('bdtx') ? returnData.concat('Benzodiazepines detoxification') : returnData;
  returnData = clinic.codes.includes('ccc') ? returnData.concat('Child care for clients’ children') : returnData;
  returnData = clinic.codes.includes('cdtx') ? returnData.concat('Cocaine detoxification') : returnData;
  returnData = clinic.codes.includes('cm') ? returnData.concat('Case management') : returnData;
  returnData = clinic.codes.includes('daut') ? returnData.concat('Drug or alcohol urine screening') : returnData;
  returnData = clinic.codes.includes('dvfp') ? returnData.concat('Domestic violence services—family or partner') : returnData;
  returnData = clinic.codes.includes('eih') ? returnData.concat('Early intervention for HIV') : returnData;
  returnData = clinic.codes.includes('hivt') ? returnData.concat('HIV testing') : returnData;
  returnData = clinic.codes.includes('hs') ? returnData.concat('Housing services') : returnData;
  returnData = clinic.codes.includes('mdtx') ? returnData.concat('Methamphetamine detoxification') : returnData;
  returnData = clinic.codes.includes('mhs') ? returnData.concat('Mental health services') : returnData;
  returnData = clinic.codes.includes('nrt') ? returnData.concat('Nicotine replacement therapy') : returnData;
  returnData = clinic.codes.includes('nsc') ? returnData.concat('Non-nicotine smoking/tobacco cessation medications') : returnData;
  returnData = clinic.codes.includes('opc') ? returnData.concat('Outreach to persons in the community') : returnData;
  returnData = clinic.codes.includes('peer') ? returnData.concat('Consumer-run (peer support services)') : returnData;
  returnData = clinic.codes.includes('shb') ? returnData.concat('Screening for Hepatitis B') : returnData;
  returnData = clinic.codes.includes('shc') ? returnData.concat('Screening for Hepatitis C') : returnData;
  returnData = clinic.codes.includes('shg') ? returnData.concat('Self-help groups') : returnData;
  returnData = clinic.codes.includes('smhd') ? returnData.concat('Screening for mental health disorders') : returnData;
  returnData = clinic.codes.includes('ssa') ? returnData.concat('Screening for substance abuse') : returnData;
  returnData = clinic.codes.includes('ssd') ? returnData.concat('Social skills development') : returnData;
  returnData = clinic.codes.includes('stdt') ? returnData.concat('STD testing') : returnData;
  returnData = clinic.codes.includes('stu') ? returnData.concat('Screening for tobacco use') : returnData;
  returnData = clinic.codes.includes('ta') ? returnData.concat('Transportation assistance') : returnData;
  returnData = clinic.codes.includes('tbs') ? returnData.concat('TB screening') : returnData;
  returnData = clinic.codes.includes('tgd') ? returnData.concat('Treatment for gambling disorder') : returnData;
  returnData = clinic.codes.includes('tid') ? returnData.concat('Treatment for Internet use disorder') : returnData;
  return returnData;
}
export const getLanguages = (clinic: Clinic): string[] => {
  let returnData: string[] = [];
  returnData = clinic.codes.includes('ah') ? returnData.concat('Assistance for the deaf and hard of hearing (may be provided by either staff counselor or on-call interpreter)') : returnData;
  returnData = clinic.codes.includes('n65') ? returnData.concat('Aleut') : returnData;
  returnData = clinic.codes.includes('n73') ? returnData.concat('Anishinaabemowin') : returnData;
  returnData = clinic.codes.includes('n4') ? returnData.concat('Cherokee') : returnData;
  returnData = clinic.codes.includes('n5') ? returnData.concat('Cheyenne') : returnData;
  returnData = clinic.codes.includes('n75') ? returnData.concat('Chickasaw') : returnData;
  returnData = clinic.codes.includes('n7') ? returnData.concat('Cocopah') : returnData;
  returnData = clinic.codes.includes('n9') ? returnData.concat('Crow') : returnData;
  returnData = clinic.codes.includes('n76') ? returnData.concat('Gwichin') : returnData;
  returnData = clinic.codes.includes('n42') ? returnData.concat('Haida') : returnData;
  returnData = clinic.codes.includes('n13') ? returnData.concat('Hopi') : returnData;
  returnData = clinic.codes.includes('n70') ? returnData.concat('Inupiaq') : returnData;
  returnData = clinic.codes.includes('n16') ? returnData.concat('Keres') : returnData;
  returnData = clinic.codes.includes('n18') ? returnData.concat('Lakota') : returnData;
  returnData = clinic.codes.includes('n22') ? returnData.concat('Mohawk') : returnData;
  returnData = clinic.codes.includes('n23') ? returnData.concat('Navajo') : returnData;
  returnData = clinic.codes.includes('n24') ? returnData.concat('Ojibwa') : returnData;
  returnData = clinic.codes.includes('n28') ? returnData.concat('Passamaquoddy') : returnData;
  returnData = clinic.codes.includes('n32') ? returnData.concat('Shoshoni') : returnData;
  returnData = clinic.codes.includes('n33') ? returnData.concat('Tewa') : returnData;
  returnData = clinic.codes.includes('n49') ? returnData.concat('Tiwa') : returnData;
  returnData = clinic.codes.includes('n34') ? returnData.concat('Tlingit') : returnData;
  returnData = clinic.codes.includes('n35') ? returnData.concat('Tohono O’odham') : returnData;
  returnData = clinic.codes.includes('n36') ? returnData.concat('Towa') : returnData;
  returnData = clinic.codes.includes('n51') ? returnData.concat('Triqui') : returnData;
  returnData = clinic.codes.includes('n79') ? returnData.concat('Tsimshian') : returnData;
  returnData = clinic.codes.includes('n40') ? returnData.concat('Yupik') : returnData;
  returnData = clinic.codes.includes('n41') ? returnData.concat('Zuni') : returnData;
  returnData = clinic.codes.includes('f110') ? returnData.concat('Akan') : returnData;
  returnData = clinic.codes.includes('f2') ? returnData.concat('Albanian') : returnData;
  returnData = clinic.codes.includes('f3') ? returnData.concat('Amharic') : returnData;
  returnData = clinic.codes.includes('f4') ? returnData.concat('Arabic') : returnData;
  returnData = clinic.codes.includes('f5') ? returnData.concat('Armenian') : returnData;
  returnData = clinic.codes.includes('f6') ? returnData.concat('Assyrian') : returnData;
  returnData = clinic.codes.includes('f8') ? returnData.concat('Belarusan') : returnData;
  returnData = clinic.codes.includes('f9') ? returnData.concat('Bengali') : returnData;
  returnData = clinic.codes.includes('f161') ? returnData.concat('Bisaya') : returnData;
  returnData = clinic.codes.includes('f10') ? returnData.concat('Bosnian') : returnData;
  returnData = clinic.codes.includes('f11') ? returnData.concat('Bulgarian') : returnData;
  returnData = clinic.codes.includes('f12') ? returnData.concat('Burmese') : returnData;
  returnData = clinic.codes.includes('f151') ? returnData.concat('Burundi') : returnData;
  returnData = clinic.codes.includes('f105') ? returnData.concat('Cebuano') : returnData;
  returnData = clinic.codes.includes('f15') ? returnData.concat('Chaldean') : returnData;
  returnData = clinic.codes.includes('f17') ? returnData.concat('Chinese') : returnData;
  returnData = clinic.codes.includes('f18') ? returnData.concat('Chuukese') : returnData;
  returnData = clinic.codes.includes('f19') ? returnData.concat('Creole') : returnData;
  returnData = clinic.codes.includes('f20') ? returnData.concat('Croatian') : returnData;
  returnData = clinic.codes.includes('f21') ? returnData.concat('Czech') : returnData;
  returnData = clinic.codes.includes('f22') ? returnData.concat('Danish') : returnData;
  returnData = clinic.codes.includes('f98') ? returnData.concat('Dari') : returnData;
  returnData = clinic.codes.includes('f23') ? returnData.concat('Dutch') : returnData;
  returnData = clinic.codes.includes('f132') ? returnData.concat('Dzongkha') : returnData;
  returnData = clinic.codes.includes('f102') ? returnData.concat('Edo') : returnData;
  returnData = clinic.codes.includes('f25') ? returnData.concat('Farsi') : returnData;
  returnData = clinic.codes.includes('f26') ? returnData.concat('Fijian') : returnData;
  returnData = clinic.codes.includes('f28') ? returnData.concat('French') : returnData;
  returnData = clinic.codes.includes('f165') ? returnData.concat('Fula') : returnData;
  returnData = clinic.codes.includes('f157') ? returnData.concat('Georgian') : returnData;
  returnData = clinic.codes.includes('f30') ? returnData.concat('German') : returnData;
  returnData = clinic.codes.includes('f31') ? returnData.concat('Greek') : returnData;
  returnData = clinic.codes.includes('f33') ? returnData.concat('Gujurati') : returnData;
  returnData = clinic.codes.includes('f108') ? returnData.concat('Hausa') : returnData;
  returnData = clinic.codes.includes('f34') ? returnData.concat('Hawaiian') : returnData;
  returnData = clinic.codes.includes('f35') ? returnData.concat('Hebrew') : returnData;
  returnData = clinic.codes.includes('f36') ? returnData.concat('Hindi') : returnData;
  returnData = clinic.codes.includes('f37') ? returnData.concat('Hmong') : returnData;
  returnData = clinic.codes.includes('f38') ? returnData.concat('Hungarian') : returnData;
  returnData = clinic.codes.includes('f147') ? returnData.concat('Ibibio') : returnData;
  returnData = clinic.codes.includes('f39') ? returnData.concat('Igbo') : returnData;
  returnData = clinic.codes.includes('f40') ? returnData.concat('Ilocano') : returnData;
  returnData = clinic.codes.includes('f164') ? returnData.concat('Ilonggo') : returnData;
  returnData = clinic.codes.includes('f42') ? returnData.concat('Italian') : returnData;
  returnData = clinic.codes.includes('f43') ? returnData.concat('Japanese') : returnData;
  returnData = clinic.codes.includes('f113') ? returnData.concat('Kannada') : returnData;
  returnData = clinic.codes.includes('f142') ? returnData.concat('Karen') : returnData;
  returnData = clinic.codes.includes('f44') ? returnData.concat('Khmer') : returnData;
  returnData = clinic.codes.includes('f45') ? returnData.concat('Khmu') : returnData;
  returnData = clinic.codes.includes('f46') ? returnData.concat('Kinyarwand') : returnData;
  returnData = clinic.codes.includes('f152') ? returnData.concat('Kirundi') : returnData;
  returnData = clinic.codes.includes('f47') ? returnData.concat('Korean') : returnData;
  returnData = clinic.codes.includes('f109') ? returnData.concat('Krio') : returnData;
  returnData = clinic.codes.includes('f135') ? returnData.concat('Kurdish') : returnData;
  returnData = clinic.codes.includes('f48') ? returnData.concat('Lao') : returnData;
  returnData = clinic.codes.includes('f49') ? returnData.concat('Latvian') : returnData;
  returnData = clinic.codes.includes('f160') ? returnData.concat('Lingala') : returnData;
  returnData = clinic.codes.includes('f99') ? returnData.concat('Lithuanian') : returnData;
  returnData = clinic.codes.includes('f52') ? returnData.concat('Macedonian') : returnData;
  returnData = clinic.codes.includes('f163') ? returnData.concat('Mai Mai') : returnData;
  returnData = clinic.codes.includes('f53') ? returnData.concat('Malay') : returnData;
  returnData = clinic.codes.includes('f54') ? returnData.concat('Malayalam') : returnData;
  returnData = clinic.codes.includes('f120') ? returnData.concat('Mandinka') : returnData;
  returnData = clinic.codes.includes('f119') ? returnData.concat('Marathi') : returnData;
  returnData = clinic.codes.includes('f58') ? returnData.concat('Mien') : returnData;
  returnData = clinic.codes.includes('f60') ? returnData.concat('Mongolian') : returnData;
  returnData = clinic.codes.includes('f61') ? returnData.concat('Nepali') : returnData;
  returnData = clinic.codes.includes('f62') ? returnData.concat('Nigerian') : returnData;
  returnData = clinic.codes.includes('f63') ? returnData.concat('Norwegian') : returnData;
  returnData = clinic.codes.includes('f167') ? returnData.concat('Nuer') : returnData;
  returnData = clinic.codes.includes('f153') ? returnData.concat('Oroma') : returnData;
  returnData = clinic.codes.includes('f64') ? returnData.concat('Palauan') : returnData;
  returnData = clinic.codes.includes('f118') ? returnData.concat('Pashto, Southern') : returnData;
  returnData = clinic.codes.includes('f66') ? returnData.concat('Polish') : returnData;
  returnData = clinic.codes.includes('f67') ? returnData.concat('Portuguese') : returnData;
  returnData = clinic.codes.includes('f65') ? returnData.concat('Punjabi') : returnData;
  returnData = clinic.codes.includes('f68') ? returnData.concat('Quiche') : returnData;
  returnData = clinic.codes.includes('f69') ? returnData.concat('Romanian') : returnData;
  returnData = clinic.codes.includes('f70') ? returnData.concat('Russian') : returnData;
  returnData = clinic.codes.includes('f71') ? returnData.concat('Samoan') : returnData;
  returnData = clinic.codes.includes('f73') ? returnData.concat('Serbian') : returnData;
  returnData = clinic.codes.includes('f74') ? returnData.concat('Serbian/Croatian') : returnData;
  returnData = clinic.codes.includes('f75') ? returnData.concat('Slavic') : returnData;
  returnData = clinic.codes.includes('f76') ? returnData.concat('Slovak') : returnData;
  returnData = clinic.codes.includes('f104') ? returnData.concat('Somali') : returnData;
  returnData = clinic.codes.includes('sp') ? returnData.concat('Spanish') : returnData;
  returnData = clinic.codes.includes('f79') ? returnData.concat('Swahili') : returnData;
  returnData = clinic.codes.includes('f80') ? returnData.concat('Swedish') : returnData;
  returnData = clinic.codes.includes('f81') ? returnData.concat('Tagalog') : returnData;
  returnData = clinic.codes.includes('f166') ? returnData.concat('Taishanese') : returnData;
  returnData = clinic.codes.includes('f154') ? returnData.concat('Taiwanese') : returnData;
  returnData = clinic.codes.includes('f82') ? returnData.concat('Tamil') : returnData;
  returnData = clinic.codes.includes('f85') ? returnData.concat('Telugu') : returnData;
  returnData = clinic.codes.includes('f86') ? returnData.concat('Thai') : returnData;
  returnData = clinic.codes.includes('f141') ? returnData.concat('Tigrinya') : returnData;
  returnData = clinic.codes.includes('f87') ? returnData.concat('Tongan') : returnData;
  returnData = clinic.codes.includes('f88') ? returnData.concat('Turkish') : returnData;
  returnData = clinic.codes.includes('f89') ? returnData.concat('Ukrainian') : returnData;
  returnData = clinic.codes.includes('f91') ? returnData.concat('Urdu') : returnData;
  returnData = clinic.codes.includes('f92') ? returnData.concat('Vietnamese') : returnData;
  returnData = clinic.codes.includes('f144') ? returnData.concat('Visayan') : returnData;
  returnData = clinic.codes.includes('f94') ? returnData.concat('Yapese') : returnData;
  returnData = clinic.codes.includes('f95') ? returnData.concat('Yiddish') : returnData;
  returnData = clinic.codes.includes('f96') ? returnData.concat('Yoruba') : returnData;
  returnData = clinic.codes.includes('f159') ? returnData.concat('Zulu') : returnData;
  return returnData;
}
export const calculateDistanceFromPoint = (latitude = 0, longitude = 0, latitude2 = 0, longitude2 = 0,
                                           returnInMiles: boolean): number => {
  if (!longitude || !longitude || !latitude || !longitude2) {
    return 0;
  }
  const R = 3958.8; // Radius of the Earth in miles
  const rlat1 = latitude * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = latitude2 * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (longitude2 - longitude) * (Math.PI / 180); // Radian difference (longitudes)
  const distanceInKm = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
  if (!returnInMiles) {
    return distanceInKm;
  } else {
    return distanceInKm * 0.621371;
  }
}
export const getDirectionsUrl = (clinic: Clinic, currentLocation: Location): string => {
  if (currentLocation) {
    const addressString = clinic.street1?.trim() + ' ' + clinic.city + ' ' + clinic.state + ' ' + clinic.zip;
    return 'https://www.google.com/maps/dir/' + currentLocation.lat + ',' + currentLocation.lng + '/' + addressString.replace(/ /g, '+');
  } else {
    return 'https://www.google.com/dir/'
  }
}
