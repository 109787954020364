class SearchEntryObject {
  constructor(inId: string, inIsDefault = false, inName: string, inValue: string, inTag: string, inDescription: string){
    this.id = inId;
    this.isDefault = inIsDefault;
    this.name = inName;
    this.value = inValue;
    this.tag = inTag;
    this.description = inDescription;
  }

  id: string;
  isDefault: boolean;
  name: string;
  value: string;
  tag: string;
  description?: string;
}
export default SearchEntryObject;
