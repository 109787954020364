import ClinicQueryData from './ClinicQueryData';
import { Clinic, ClinicQueryResponse } from './Clinic';
import { analytics } from '../../config/FirebaseConfig';
import { callWithResponse } from '../utility/HttpUtilities';

export const getClinics = async (queryData: ClinicQueryData): Promise<ClinicQueryResponse> => {
  const clinicQueryResponse = new ClinicQueryResponse();
  try {
    const theUrl = '/clinics';
    const codes = [];
    if (queryData.languageCode) codes.push(queryData.languageCode);
    if (queryData.treatmentTypeCode) codes.push(queryData.treatmentTypeCode);
    if (queryData.paymentOptionsCode) codes.push(queryData.paymentOptionsCode);
    if (queryData.ageCode) codes.push(queryData.ageCode);
    if (queryData.specialProgramCode) codes.push(queryData.specialProgramCode);
    if (queryData.matCode) codes.push(queryData.matCode);
    const locationQuery = {
      latitude: queryData.searchLocation.lat,
      longitude: queryData.searchLocation.lng,
      distance_in_mi: queryData.radiusInMi
    };
    const params = {
      search_location: locationQuery, codes: codes,
      offset: queryData.offset, limit: queryData.limit
    };
    const response = await callWithResponse(theUrl, 'GET', params);
    clinicQueryResponse.clinics = JSON.parse(response.data.content);
    clinicQueryResponse.total_elements = response.data.total_elements;
  } catch (error) {
    const message = 'Error getting clinics ' + error.message;
    console.log(message);
    analytics.logEvent('error', { class: 'ClinicService', message: message });
    throw new Error(message);
  }
  return clinicQueryResponse;
};
export const getClinic = async (clinicUID: string): Promise<Clinic> => {
  let clinic;
  try {
    const theUrl = '/clinics/' + clinicUID;
    const response = await callWithResponse(theUrl, 'GET', {});
    const data = response.data;
    clinic = new Clinic();
    clinic.uid = data.uid;
  } catch (error) {
    const message = 'Error getting clinic ' + error.message;
    console.log(message);
    analytics.logEvent('error', { class: 'ClinicService', message: message });
    throw new Error(message);
  }
  return clinic;
};
export const createClinic = async (clinicData: Clinic): Promise<Clinic> => {
  let clinic;
  try {
    const theUrl = '/clinics';
    const response = await callWithResponse(theUrl, 'POST', { clinicData: JSON.stringify(clinicData) });
    const data = response.data;
    clinic = new Clinic();
    clinic.uid = data.uid;
  } catch (error) {
    const message = 'Error creating clint ' + error.message;
    console.log(message);
    analytics.logEvent('error', { class: 'ClinicService', message: message });
    throw new Error(message);
  }
  return clinic;
};
export const updateClinic = async (clinicUid: string, clinicData: Clinic): Promise<Clinic> => {
  let clinic;
  try {
    const theUrl = '/clinics/' + clinicUid;
    const response = await callWithResponse(theUrl, 'PUT', { clinicData: JSON.stringify(clinicData) });
    const data = response.data;
    clinic = new Clinic();
    clinic.uid = data.uid;
  } catch (error) {
    const message = 'Error updating clinic ' + error.message;
    console.log(message);
    analytics.logEvent('error', { class: 'ClinicService', message: message });
    throw new Error(message);
  }
  return clinic;
};
