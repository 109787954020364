import { Redirect, Route, Switch } from 'react-router-dom';
import Search from '../components/search/Search';
import React from 'react';

const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Redirect exact path='/' to='/search'/>
      <Route exact path="/search" component={Search}/>
    </Switch>
  )
};
export default Routes;
