import React from 'react';
import { Container } from 'reactstrap';
import Routes from './routes/Routes';
import { Router } from 'react-router-dom';
import { ErrorBoundary } from './config/BugSnagConfig';
import { createBrowserHistory } from 'history';
import { logPageView } from './config/FirebaseConfig';

const history = createBrowserHistory();
history.listen(location => {
  console.log('pageview is being logged to ' + location.pathname);
  logPageView(location.pathname);
});

function App(): JSX.Element{
  return (
    <ErrorBoundary>
      <Container fluid>
        <Router history={history}>
          <Routes/>
        </Router>
      </Container>
    </ErrorBoundary>
  );
}

export default App;
