import SearchEntryObject from './SearchEntryObject';

class SearchHeaderObject {
  constructor(inHeader: string, inEntries: Array<SearchEntryObject>){
    this.header = inHeader;
    this.entries = inEntries;
  }

  header: string;
  entries: Array<SearchEntryObject>;
}
export default SearchHeaderObject;
