import React from 'react';
import styles from './ClinicServiceOffering.module.css';
import { Col } from 'reactstrap';

interface ClinicServiceProps {
  header: string;
  services: Array<string>;
}
const ClinicServiceOffering = (props: ClinicServiceProps): JSX.Element => {
  return (
    <Col md={6}>
      <div className={styles.serviceHeader}>{props.header}</div>
      {props.services.length > 0 &&
      <ul className={styles.serviceGroup}>
        {props.services.map((output: string) =>
          <li key={output}>{output}</li>
        )}
      </ul>
      }
      {props.services.length === 0 &&
      <div className={styles.missingServiceData}>No special options reported</div>
      }
    </Col>
  )
};
export default ClinicServiceOffering;
