import styles from './SearchHeader.module.css';
import { Card, CardBody, CardHeader, Collapse, FormGroup } from 'reactstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import React, { useState } from 'react';
import SearchHeaderObject from './SearchHeaderObject';
import SearchEntry from './SearchEntry';

export interface SearchHeaderProps {
  searchHeaderObject: SearchHeaderObject;
  handleSearchCodes: (event: React.FormEvent<HTMLInputElement>) => Promise<void>;
}
const SearchHeader = (props: SearchHeaderProps): JSX.Element => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const toggleType = (): void => setIsToggleOpen(!isToggleOpen);
  return (
    <Card className={styles.optionCard} onClick={toggleType}>
      <CardHeader>
        <div className={styles.toggleClick} onClick={toggleType}>
          <div className={styles.optionHeader}>{props.searchHeaderObject.header}</div>
          {isToggleOpen &&
            <div className={styles.collapseButton}>
              <FaMinus size=".8em" className={styles.collapseIcon}/>
            </div>
          }
          {!isToggleOpen &&
            <div className={styles.collapseButton}>
              <FaPlus size=".8em" className={styles.collapseIcon}/>
            </div>
          }
        </div>
      </CardHeader>
      <Collapse isOpen={isToggleOpen}>
        <CardBody>
          <FormGroup tag="fieldset">
            {props.searchHeaderObject.entries.map((value) => {
              return <div key={value.id}>
                <SearchEntry searchEntryObject={value} handleSearchCodes={props.handleSearchCodes}/>
              </div>
            })}
          </FormGroup>
        </CardBody>
      </Collapse>
    </Card>
  );
};
export default SearchHeader;
