import React from 'react';
import { GoogleMapProvider, MapBox, Marker } from '@googlemap-react/core/dist';

interface MapProps {
  latitude: number;
  longitude: number;
  name: string;
}

function ClinicMap(props: MapProps): JSX.Element{
  const { latitude, longitude, name } = props;
  const center = { lat: latitude, lng: longitude };
  return (
    <GoogleMapProvider>
      <MapBox
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        opts={{
          center: center,
          zoom: 11,
        }}
        style={{
          height: '250px',
          width: '100%',
        }}
      />
      <Marker
        id="marker"
        opts={{
          draggable: false,
          label: name,
          position: center,
        }}
      />
    </GoogleMapProvider>
  );
}

export default ClinicMap;
