import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * This is a utility method to handle calls to the Firebase function system.
 * @param theUrl The url fragment to call for the health system.  This will be added to the base url.
 * @param method The http method to use (GET, POST, PUT, DELETE)
 * @param data The data to send to the health system
 */
export const callWithResponse = async (theUrl: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                                       data: Record<string, any | string | number | undefined | null> | string): Promise<AxiosResponse> => {
  const fullUrl = process.env.REACT_APP_FUNCTION_BASE_URL + theUrl;
  const options: AxiosRequestConfig = {
    url: fullUrl,
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (method === 'GET') {
    options.params = data;
  } else {
    options.data = data;
  }
  return axios(options)
};
/**
 * This is a utility method to handle calls to the Firebase function system.
 * @param theUrl The url fragment to call for the health system.  This will be added to the base url.
 * @param method The http method to use (GET, POST, PUT, DELETE)
 * @param data The data to send to the health system
 */
export const callUnSecureWithResponse = async (theUrl: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                                               data: Record<string, string | number>): Promise<AxiosResponse> => {
  const fullUrl = process.env.REACT_APP_FUNCTION_BASE_URL + theUrl;
  const options: AxiosRequestConfig = {
    url: fullUrl,
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return axios(options)
};
