class Location {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number){
    this.lat = lat;
    this.lng = lng;
  }
}
export default Location;
