import styles from './SearchEntry.module.css';
import { FormGroup, Input, Label } from 'reactstrap';
import React from 'react';
import SearchEntryObject from './SearchEntryObject';

interface Props {
  searchEntryObject: SearchEntryObject;
  handleSearchCodes: (event: React.FormEvent<HTMLInputElement>) => Promise<void>;
}
const SearchEntry = (props: Props): JSX.Element => {
  return (
    <FormGroup check>
      <Label check className={styles.optionLabel}>
        <Input
          defaultChecked={props.searchEntryObject.isDefault}
          type="radio"
          id={props.searchEntryObject.id}
          value={props.searchEntryObject.value}
          onChange={props.handleSearchCodes}
          name={props.searchEntryObject.name}/>
        <div className={styles.optionTag}>{props.searchEntryObject.tag}</div>
        {props.searchEntryObject.description &&
        <div className={styles.optionDescription}>{props.searchEntryObject.description}</div>
        }
      </Label>
    </FormGroup>
  );
};
export default SearchEntry;
