import React from 'react';
import { Col, Navbar, NavbarBrand, Row } from 'reactstrap';

const NavBar = (): JSX.Element => {
  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/">Support Finder</NavbarBrand>
          </Navbar>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default NavBar;
