import firebase from 'firebase/app';
import 'firebase/analytics';

export const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
const analytics = firebaseApp.analytics();
const logPageView = (pathname: string): void => analytics.logEvent('page_view', { 'page_location': pathname });
const logError = (className: string, message: string): void => analytics.logEvent('error', {
  className,
  message
});
const logSearch = (className: string, queryData: string, resultCount: number): void => analytics.logEvent('search', {
  className,
  queryData,
  resultCount
});
export {
  analytics,
  logError,
  logSearch,
  logPageView,
};
