import React, { useEffect, useState } from 'react';
import {
  calculateDistanceFromPoint,
  Clinic,
  getAccreditation,
  getAgeGroups,
  getAlcoholTreatment,
  getAncillaryServices,
  getAssessmentPreTreatment,
  getClientServicesFor,
  getCounselingAndEducationServices,
  getDirectionsUrl,
  getFacilityOperation,
  getGenderAccepted,
  getLanguages,
  getOpioidServices,
  getPaymentAccepted,
  getPaymentAssistance,
  getServiceSetting,
  getSmokingPolicy,
  getTransitionalServices,
  getTreatmentApproaches,
  getTypeOfCare
} from './Clinic';
import Location from './Location';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import styles from './ClinicDetails.module.css';
import { FaCar, FaCheck, FaCreditCard, FaDirections, FaPhone } from 'react-icons/fa';
import ClinicMap from './ClinicMap';
import ClinicServiceOffering from './ClinicServiceOffering';
import ClinicOffering from './ClinicOffering';

export interface ClinicDetailsProps {
  clinic: Clinic;
  showDetails: Function;
  searchLat: number;
  searchLng: number;
}
const ClinicDetails = (props: ClinicDetailsProps): JSX.Element => {
  const [serviceList, setServiceList] = useState<Array<ClinicOffering>>([]);
  const [searchLocation, setSearchLocation] = useState(new Location(0, 0));
  useEffect(() => {
    const serviceList: Array<ClinicOffering> = [];
    serviceList.push({ header: 'Payment Assistance', services: getPaymentAssistance(props?.clinic) });
    serviceList.push({ header: 'Types Of Care', services: getTypeOfCare(props?.clinic) });
    serviceList.push({ header: 'Service Settings', services: getServiceSetting(props?.clinic) });
    serviceList.push({
      header: 'Facility Operations(E.G. Private Public)',
      services: getFacilityOperation(props?.clinic)
    });
    serviceList.push({ header: 'Facility Smoking Policy', services: getSmokingPolicy(props?.clinic) });
    serviceList.push({ header: 'Assessment/Pre-Treatment', services: getAssessmentPreTreatment(props?.clinic) });
    serviceList.push({
      header: 'Type of Alcohol use Disorder Treatment',
      services: getAlcoholTreatment(props?.clinic)
    });
    serviceList.push({ header: 'Type of Opioid Treatment', services: getOpioidServices(props?.clinic) });
    serviceList.push({ header: 'Additional Client Services', services: getAncillaryServices(props?.clinic) });
    serviceList.push({ header: 'Accreditation', services: getAccreditation(props?.clinic) });
    serviceList.push({ header: 'Treatment Approaches', services: getTreatmentApproaches(props?.clinic) });
    serviceList.push({ header: 'Client Services For', services: getClientServicesFor(props?.clinic) });
    serviceList.push({
      header: 'Counseling and Education Services',
      services: getCounselingAndEducationServices(props?.clinic)
    });
    serviceList.push({ header: 'Transitional Services', services: getTransitionalServices(props?.clinic) });
    serviceList.push({ header: 'Age Groups Accepted', services: getAgeGroups(props?.clinic) });
    serviceList.push({ header: 'Genders Accepted', services: getGenderAccepted(props?.clinic) });
    serviceList.push({ header: 'Language Services', services: getLanguages(props?.clinic) });
    setServiceList(serviceList);
    const clinicLat = props.clinic.latitude;
    const clinicLng = props.clinic.longitude;
    setSearchLocation(new Location(props.searchLat, props.searchLng));
    props.clinic.distanceInMiles =
      Number(calculateDistanceFromPoint(Number(props.searchLat), Number(props.searchLng), clinicLat,
        clinicLng, true)?.toFixed(2));
  }, [props.clinic, props.searchLat, props.searchLng]);
  const goBack = (): void => {
    props.showDetails(false, '');
  };
  return (
    <React.Fragment>
      <Button style={{backgroundColor:'#2380bf', color:"#ffffff", fontWeight:'bold', padding:20, margin:10}} onClick={goBack}>Back to search results</Button>
      <Row>
        <Col md={9}>
          <div className={styles.clinicNameHeader}>
            <div className={styles.clinicName1}>{props.clinic?.name} </div>
            <div className={styles.clinicName2}>{props.clinic?.name2}</div>
          </div>
          {props.clinic?.website?.length > 8 &&
          <div className={styles.clinicWebsite}><a href={props.clinic?.website}
                                                   target="_blank"
                                                   rel="noopener noreferrer"> {props.clinic?.website}</a></div>
          }
          {props.clinic?.website?.length <= 8 &&
          <div className={styles.clinicWebsite}>No Website Reported</div>
          }
        </Col>
        <Col md={3}>
          <a href={'tel:' + props.clinic?.phone} className={styles.clinicPhone}><span
            className={styles.clinicPhoneIcon}> <FaPhone/></span>{props.clinic?.phone}</a>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className={styles.mainCard}>
            <CardHeader className={styles.mainCardHeader}>
              <div className={styles.locationHeader}>Location</div>
              <div className={styles.clinicDistanceHeader}>{props.clinic?.distanceInMiles} miles away</div>
            </CardHeader>
            <CardBody>
              <div className={styles.mapBox}>
                <ClinicMap latitude={Number(props.clinic?.latitude)} longitude={Number(props.clinic?.longitude)}
                           name={props.clinic?.name}/>
              </div>
              <div className={styles.addressGroup}>
                <div className={styles.address}>{props.clinic?.street1} {props.clinic?.street2}</div>
                <div
                  className={styles.address}>{props.clinic?.city} {props.clinic?.state} {props.clinic?.zip}</div>
              </div>
              <Button className={styles.directionsButton}
                      href={getDirectionsUrl(props?.clinic, searchLocation)}
                      target="_blank"
                      rel="noopener noreferrer"><span
                className={styles.clinicDirectionsIcon}> <FaDirections/></span>Get Directions</Button>
            </CardBody>
            <CardFooter className={styles.mainCardFooter}>
              {props.clinic.codes.includes('ta') &&
              <div className={styles.instructions}><span
                className={styles.carIcon}><FaCar size={30}/></span>This facility <span
                className={styles.transportationAssistance}>does offer</span> transportation
                assistance.</div>
              }
              {!props.clinic.codes.includes('ta') &&
              <div className={styles.instructions}><span
                className={styles.carIcon}><FaCar size={30}/></span>This facility <span
                className={styles.transportationAssistance}>does not offer</span> transportation
                assistance.</div>
              }
            </CardFooter>
          </Card>
        </Col>
        <Col md={6}>
          <Card className={styles.mainCard}>
            <CardHeader className={styles.mainCardHeader}>
              <div>Payment, insurance, or funding accepted</div>
            </CardHeader>
            <CardBody>
              <ul className={styles.paymentAccepted}>
                {getPaymentAccepted(props?.clinic).map((output: string) =>
                  <li key={output}> <span
                    className={styles.checkIcon}> <FaCheck/></span>{output}</li>
                )}
              </ul>
            </CardBody>
            <CardFooter className={styles.mainCardFooter}>
              <div className={styles.instructions}><span
                className={styles.clinicCreditCardIcon}> <FaCreditCard size={30}/></span>
                Contact this facility to make sure they take your specific insurance or coverage
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Card>
        <CardHeader className={styles.mainCardHeader}>Services</CardHeader>
        <CardBody>
          <Row>
            {serviceList.map((value) => {
              return <ClinicServiceOffering key={value.header} header={value.header} services={value.services}/>
            })}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default ClinicDetails;
