import { Dispatch } from 'redux'
import ClinicQueryData from '../../components/clinic/ClinicQueryData';
import { CLEAR_CLINICS, END_LOADING, ERROR_LOADING, GET_CLINICS, START_LOADING } from '../constants/action-types';
import { getClinics } from '../../components/clinic/ClinicService';
import { calculateDistanceFromPoint, Clinic } from '../../components/clinic/Clinic';
import { logError, logSearch } from '../../config/FirebaseConfig';

export const clearClinicsAction = () => (dispatch: Dispatch): void => {
  dispatch({
    type: CLEAR_CLINICS,
    clinics: [],
    totalCount: 0,
    queryData: new ClinicQueryData(),
  })
};
export const getClinicsAction = (queryData: ClinicQueryData) => (dispatch: Dispatch): void => {
  let clinics: Clinic[] = [];
  dispatch({
    type: START_LOADING,
  });
  getClinics(queryData).then(response => {
    clinics = response.clinics;
    clinics.forEach(clinic => {
      const distanceInMiles =
        calculateDistanceFromPoint(queryData.searchLocation.lat, queryData.searchLocation.lng,
          clinic.latitude, clinic.longitude, true);
      if (distanceInMiles) {
        clinic.distanceInMiles = Number(distanceInMiles.toFixed(2));
      }
    });
    clinics.sort(Clinic.compare);
    logSearch('getClinicsAction', JSON.stringify(queryData), clinics.length);
    dispatch({
      type: GET_CLINICS,
      clinics: clinics,
      totalCount: response.total_elements,
      queryData: queryData,
    });
    dispatch({
      type: END_LOADING,
    });
  }, error => {
    const message = 'Error retrieving clinics with query ' + queryData + ' error ' + error;
    logError('getClinicsAction', message);
    let httpStatusCode = 400;
    if (message.indexOf('403') > 0) {
      httpStatusCode = 403;
    }
    dispatch({
      type: ERROR_LOADING,
      errors: error,
      httpStatus: httpStatusCode,
    })
  });
};

