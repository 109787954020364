import { ClinicState, ClinicTypes } from './types';
import { CLEAR_CLINICS, END_LOADING, ERROR_LOADING, GET_CLINICS, START_LOADING } from '../constants/action-types';
import ClinicQueryData from '../../components/clinic/ClinicQueryData';
import { Clinic } from '../../components/clinic/Clinic';

export const initialState = {
  clinics: new Array<Clinic>(),
  totalCount: 0,
  loading: false,
  queryData: new ClinicQueryData(),
  errors: new Array<string>(),
};
export default (state: ClinicState = initialState, action: ClinicTypes) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case ERROR_LOADING:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case GET_CLINICS:
      return {
        ...state,
        clinics: action.clinics,
        totalCount: action.totalCount,
        queryData: action.queryData,
        errors: null,
      };
    case CLEAR_CLINICS:
      return {
        ...state,
        totalCount: 0,
        queryData: new ClinicQueryData(),
        clinics: [],
      };
    default:
      return state
  }
}
