import React, { ChangeEvent, useEffect, useState } from 'react';
import { buildLanguageList, buildSearchCriteria, Clinic } from '../clinic/Clinic';
import ClinicQueryData from '../clinic/ClinicQueryData';
import ClinicDetails from '../clinic/ClinicDetails';
import NavBar from '../navbar/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Location from '../clinic/Location';
import TFSpinner from '../spinner/TFSpinner';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import PaginationComponent from 'react-reactstrap-pagination';
import SearchHeader from './SearchHeader';
import styles from './Search.module.css'
import ClinicSummary from '../clinic/ClinicSummary';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { clearClinicsAction, getClinicsAction } from '../../store/clinic/actions';
import { logError } from '../../config/FirebaseConfig';

const pageSize = 10;
const Search = (): JSX.Element => {
  const clinics = useSelector((state: RootState) => state.clinicReducer.clinics);
  const totalCount = useSelector((state: RootState) => state.clinicReducer.totalCount);
  const loading = useSelector((state: RootState) => state.clinicReducer.loading);
  const queryData = useSelector((state: RootState) => state.clinicReducer.queryData);
  const dispatch = useDispatch();
  const [searchCriteria, setSearchCriteria] = useState(buildSearchCriteria());
  const [languages, setLanguages] = useState(buildLanguageList());
  const [searchRan, setSearchRan] = useState(false);
  const [address, setAddress] = useState('');
  const [distance, setDistance] = useState(10);
  const [resultMessage, setResultMessage] = useState('');
  const [currentClinic, setCurrentClinic] = useState(new Clinic());
  const [showDetailToggle, setShowDetailToggle] = useState(false);
  const [beginEntry, setBeginEntry] = useState(0);
  const [endEntry, setEndEntry] = useState(pageSize);
  const [location, setLocation] = useState(new Location(0, 0),);
  const [treatmentTypeCode, setTreatmentTypeCode] = useState('');
  const [paymentOptionCode, setPaymentOptionCode] = useState('');
  const [ageCode, setAgeCode] = useState('');
  const [languageCode, setLanguageCode] = useState('');
  const [specialProgramCode, setSpecialProgramCode] = useState('');
  const [matType, setMatType] = useState('');
  const buildResultString = async (): Promise<void> => {
    let message;
    switch (totalCount) {
      case 0:
        message = 'No facilities found within ' + distance + ' miles from ' + address;
        setResultMessage(message);
        break;
      case 1:
        message = '1 facility found within ' + distance + ' miles from ' + address;
        setResultMessage(message);
        break;
      default:
        message = totalCount + ' facilities found within ' +
          distance + ' miles from ' + address;
        setResultMessage(message);
    }
  };
  useEffect(() => {
    buildResultString();
  }, [loading]);
  const maxPageNumbers = 5;
  const clearCriteria = async (): Promise<void> => {
    dispatch(clearClinicsAction());
    setAddress('');
    setDistance(10);
    setSearchRan(false);
    setTreatmentTypeCode('');
    setLanguageCode('');
    setSearchCriteria(buildSearchCriteria());
    setLanguages(buildLanguageList());
  };
  const handlePaging = (data: number): void => {
    const begin = (data - 1) * pageSize;
    const end = begin + pageSize;
    setBeginEntry(begin);
    setEndEntry(end);
    window.scrollTo(0, 0);
  };
  const runSearch = async (): Promise<void> => {
    if (!address) {
      return;
    }
    setResultMessage('');
    const queryData = new ClinicQueryData();
    queryData.offset = beginEntry;
    queryData.limit = pageSize;
    queryData.searchLocation = location;
    queryData.radiusInMi = distance;
    queryData.address = address;
    queryData.treatmentTypeCode = treatmentTypeCode ? treatmentTypeCode : null;
    queryData.matCode = matType ? matType : null;
    queryData.languageCode = languageCode ? languageCode : null;
    queryData.ageCode = ageCode ? ageCode : null;
    queryData.specialProgramCode = specialProgramCode ? specialProgramCode : null;
    queryData.paymentOptionsCode = paymentOptionCode ? paymentOptionCode : null;
    dispatch(getClinicsAction(queryData));
    setSearchRan(true);
  };
  useEffect(() => {
    runSearch();
  }, [beginEntry, distance, location, treatmentTypeCode, matType, languageCode, ageCode, specialProgramCode, paymentOptionCode]);
  const onChangeDistance = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    await setDistance(Number(event.target.value));
  };
  const handleSearchCodes = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
    const { checked, value, name } = event.currentTarget;
    if (checked) {
      switch (name) {
        case 'treatmentTypeCode':
          await setTreatmentTypeCode(value);
          break;
        case 'paymentOptionCode':
          setPaymentOptionCode(value);
          break;
        case 'ageCode':
          await setAgeCode(value);
          break;
        case 'specialProgramCode':
          await setSpecialProgramCode(value);
          break;
        case 'matCode':
          await setMatType(value);
          break;
        default:
          console.log('Unsupported code for change' + name);
          break;
      }
    }
  };
  const handleSelect = async (address: string): Promise<void> => {
    await setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const searchLocation = new Location(latLng.lat, latLng.lng);
      setLocation(searchLocation);
    } catch (error) {
      const message = 'Error getting lat lng for address ' + address;
      logError('Search', message);
    }
  };
  const showDetails = async (value: boolean, clinicUid: string): Promise<void> => {
    const foundClinic = clinics.find((clinic) => clinic.uid === clinicUid);
    if (clinicUid && foundClinic) {
      setShowDetailToggle(value);
      setCurrentClinic(foundClinic);
      window.scrollTo(0, 0);
    } else {
      setShowDetailToggle(value);
    }
  };
  return (
    <React.Fragment>
      <NavBar/>
      {loading && <TFSpinner/>}
      <Collapse isOpen={!showDetailToggle}>
        <Row>
          <Col md={3}>
            <Card>
              <CardHeader>
                <Button color="link" onClick={clearCriteria}>Reset Search</Button>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="location">Location</Label>
                    <PlacesAutocomplete
                      value={address}
                      onChange={value => setAddress(value)}
                      onSelect={handleSelect}
                      shouldFetchSuggestions={address.length > 1}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'City or Zip Code',
                              className: 'location-search-input',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item-active'
                                : 'suggestion-item';
                              return (
                                <div style={{cursor:'pointer', paddingBottom:3, borderBottom:'1px solid black'}} key={suggestion.placeId}
                                     {...getSuggestionItemProps(suggestion, {
                                       className,
                                     })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormGroup>
                  <FormGroup>
                    <Label for="distance">Distance</Label>
                    <Input style={{cursor:'pointer'}} type="select" name="distance" id="distance" value={distance}
                           onChange={onChangeDistance}>
                      <option value={5}>5 Miles</option>
                      <option value={10}>10 Miles</option>
                      <option value={15}>15 Miles</option>
                      <option value={25}>25 Miles</option>
                      <option value={50}>50 Miles</option>
                      <option value={100}>100 Miles</option>
                      <option value={250}>250 Miles</option>
                      <option value={500}>500 Miles</option>
                      <option value={1000}>1000 Miles</option>
                    </Input>
                  </FormGroup>
                  {searchCriteria.map((value) => {
                    return <SearchHeader key={value.header} handleSearchCodes={handleSearchCodes}
                                         searchHeaderObject={value}/>
                  })}
                  <Card className={styles.languageCard}>
                    <CardHeader>
                      <div className={styles.languageOptionHeader}>Other Languages Spoken</div>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Input type="select" name="languageCode" id="languageCode"
                               onChange={e => setLanguageCode(e.currentTarget.value)}
                               value={languageCode}>
                          {languages.map((language) => {
                            return <option key={language.key} value={language.value}>{language.name}</option>
                          })}
                        </Input>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col>
            {!loading && searchRan &&
            <div className={styles.resultHeader}>{resultMessage}</div>
            }
            {totalCount > 0 &&
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      {clinics?.map(clinic => {
                        return <ClinicSummary key={clinic?.uid} clinic={clinic} showDetails={showDetails}
                                              queryData={queryData}/>
                      })
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <PaginationComponent totalItems={totalCount}
                                           pageSize={pageSize}
                                           maxPaginationNumbers={maxPageNumbers}
                                           onSelect={handlePaging}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            }
            {totalCount === 0 &&
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div className={styles.instructionHeader}>Searching for treatment options</div>
                      <p className={styles.instructionText}>
                        Start by entering your ZIP code. Locations within 10 miles will be shown first, and you can
                        change
                        the
                        distance to show more or fewer locations.

                        Use the search filters to find locations that match specific needs, like the type of treatment
                        offered,
                        groups served, or medication availability.
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            }
          </Col>
        </Row>
      </Collapse>
      <Collapse isOpen={showDetailToggle}>
        <Row>
          <Col md={12}>
            {currentClinic.uid &&
            <ClinicDetails clinic={currentClinic}
                           showDetails={showDetails}
                           searchLat={queryData?.searchLocation?.lat}
                           searchLng={queryData?.searchLocation?.lng}/>
            }
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};
export default Search;
