import Location from './Location';

class ClinicQueryData {
  offset: number;
  limit: number;
  radiusInMi = 5;
  address: string;
  name: string;
  treatmentTypeCode: string;
  paymentOptionsCode: string;
  ageCode: string;
  specialProgramCode: string;
  languageCode: string;
  matCode: string;
  searchLocation: Location = new Location(0, 0);
  clearData = (): void => {
    this.radiusInMi = 5;
    this.address = '';
    this.name = '';
    this.treatmentTypeCode = null;
    this.paymentOptionsCode = null;
    this.ageCode = null;
    this.specialProgramCode = null;
    this.languageCode = null;
    this.matCode = null;
    this.searchLocation = new Location(0, 0);
  }
}
export default ClinicQueryData;
