import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import configureStore from './store/configureStore';

const store = configureStore();
const Root = (): JSX.Element => (
  <Provider store={store}>
    <App/>
  </Provider>
);
ReactDOM.render(<Root/>, document.getElementById('root'));
